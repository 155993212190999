<template>
    <div>
        <div class="mx-0 d-flex pb-2">

            <div>
                <v-icon @click="backToList"
                        class="text-black"
                        style="cursor: pointer;">mdi-arrow-left</v-icon>
            </div>
            <div class="align-center"
                 v-if="route">
                <p style="font-size: 18px;"
                   class="font-weight-semibold text-black ml-5">
                    {{ $t('Update Owner') }}
                </p>

            </div>
            <div class="align-center"
                 v-if="!route">
                <p style="font-size: 18px;"
                   class="font-weight-semibold text-black ml-5">
                    {{ $t('Add New Owner') }}
                </p>

            </div>
        </div>
        <v-divider class="mt-n2"></v-divider>
        <v-form class="multi-col-validation"
                lazy-validation
                ref="form"
                v-model="valid">
            <v-card class="pa-3 mt-7">
                <v-card-text class="d-flex align-center flex-wrap pb-0">
                    <div class="d-flex align-center pb-5">
                        <div class="d-flex align-center">
                            <span class="font-weight-semibold text-base text-green">
                                {{ $t('User Details') }}
                            </span>
                        </div>
                    </div>

                    <v-spacer></v-spacer>

                    <div class="d-flex align-center pb-5">

                    </div>
                </v-card-text>

                <!-- <v-divider></v-divider> -->
                <v-card-text>
                    <v-row class="mt-0">
                        <v-col cols="12"
                               md="6"
                               lg="4"
                               sm="12">
                            <p class="font-weight-semibold mb-0 text-black">
                                {{ $t('Owner Name') }}
                            </p>
                            <v-text-field :placeholder="$t('Owner Name')"
                                          variant="underlined"
                                          :rules="[genericRules.required]"
                                          @input="validateInput"
                                          :disabled="view"
                                          class="text-fields"
                                          v-model="owner.owner_name"></v-text-field>
                        </v-col>
                        <v-col cols="12"
                               md="6"
                               lg="4"
                               sm="12">
                            <p class="font-weight-semibold mb-0 text-black">
                                {{ $t('Owner Name Ar') }}
                            </p>
                            <v-text-field :placeholder="$t('Owner Name Ar')"
                                          variant="underlined"
                                          :rules="[genericRules.required]"
                                          @input="validateInput"
                                          :disabled="view"
                                          class="text-fields"
                                          v-model="owner.name_ar"></v-text-field>
                        </v-col> 
                        <v-col cols="12"
                               lg="4"
                               md="6"
                               sm="12">
                            <p class="font-weight-semibold mb-0 text-black">
                                {{ $t('Owner Type') }}
                            </p>
                            <v-select :items="ownerTypes"
                                      :placeholder="$t('Owner Type')"
                                      variant="underlined"
                                      hide-details
                                      :disabled="view"
                                      :rules="[genericRules.required]"
                                      class="text-fields"
                                      v-model="owner.owner_type"></v-select>
                        </v-col>
                        <v-col cols="12"
                               lg="4"
                               md="6"
                               sm="12">
                            <p class="font-weight-semibold mb-0 text-black">
                                {{ $t('ID No') }}
                            </p>
                            <v-text-field :placeholder="$t('ID No')"
                                          variant="underlined"
                                          hide-details
                                          :disabled="view"
                                          :rules="[genericRules.required]"
                                          class="text-fields"
                                          v-model="owner.information_id"></v-text-field>
                        </v-col>
                        <v-col cols="12"
                               lg="4"
                               md="6"
                               sm="12">
                            <p class="font-weight-semibold mb-0 text-black">
                                {{ $t('Certificate of Owner Registration') }}
                            </p>
                            <v-text-field :placeholder="$t('Certificate of Owner Registration')"
                                          variant="underlined"
                                          hide-details
                                          :disabled="view"
                                          :rules="[genericRules.required]"
                                          class="text-fields"
                                          v-model="owner.certificate"></v-text-field>

                        </v-col>
                        <!-- <v-col cols="12"
                               lg="4"
                               md="6"
                               sm="12">
                            <p class="font-weight-semibold mb-0 text-black">
                                {{ $t('Country') }}
                            </p>
                            <v-select :items="countries"
                                      :placeholder="$t('Country')"
                                      variant="underlined"
                                      hide-details
                                      :disabled="view"
                                      item-text="name"
                                      item-value="id"
                                      :rules="[genericRules.required]"
                                      class="text-fields"
                                      v-model="owner.country_id"></v-select>
                        </v-col> -->
                        <v-col cols="12"
                               lg="4"
                               md="6"
                               sm="12">
                            <p class="font-weight-semibold mb-0 text-black">
                                {{ $t('Unit') }}
                            </p>
                            <v-text-field :placeholder="$t('Unit')"
                                          variant="underlined"
                                          hide-details
                                          :disabled="view"
                                          :rules="[genericRules.required]"
                                          class="text-fields"
                                          v-model="owner.unit"></v-text-field>
                        </v-col>
                        <v-col cols="12"
                               lg="4"
                               md="6"
                               sm="12">
                            <p class="font-weight-semibold mb-0 text-black">
                                {{ $t('Street') }}
                            </p>
                            <v-text-field :placeholder="$t('Street')"
                                          variant="underlined"
                                          hide-details
                                          :disabled="view"
                                          :rules="[genericRules.required]"
                                          class="text-fields"
                                          v-model="owner.address"></v-text-field>
                        </v-col>
                        <v-col cols="12"
                               lg="4"
                               md="6"
                               sm="12">
                            <p class="font-weight-semibold mb-0 text-black">
                                {{ $t('District') }}
                            </p>
                            <v-text-field :placeholder="$t('District')"
                                          variant="underlined"
                                          :disabled="view"
                                          @input="validateInput"
                                          :rules="[genericRules.required]"
                                          class="text-fields"
                                          v-model="owner.district"></v-text-field>
                        </v-col>
                        <!-- <v-col cols="12"
                               lg="4"
                               md="6"
                               sm="12">
                            <p class="font-weight-semibold mb-0 text-black">
                                {{ $t('Structure No') }}
                            </p>
                            <v-text-field :placeholder="$t('Structure No')"
                                          variant="underlined"
                                          hide-details
                                          :disabled="view"
                                          :rules="[genericRules.required]"
                                          class="text-fields"
                                          v-model="owner.structure_number"></v-text-field>
                        </v-col> -->
                        <v-col cols="12"
                               lg="4"
                               md="6"
                               sm="12">
                            <p class="font-weight-semibold mb-0 text-black">
                                {{ $t('City') }}
                            </p>
                            <v-select :items="allCities"
                                      :placeholder="$t('City')"
                                      variant="underlined"
                                      item-text="name"
                                      item-value="id"
                                      :disabled="view"
                                      :rules="[genericRules.required]"
                                      class="text-fields"
                                      v-model="owner.city"></v-select>

                        </v-col>
                        <v-col cols="12"
                               lg="4"
                               md="6"
                               sm="12">
                            <p class="font-weight-semibold mb-0 text-black">
                                {{ $t('Phone') }}
                            </p>

                            <!-- <v-text-field :placeholder="$t('Phone')"
                                          variant="underlined"
                                          hide-details
                                          :disabled="view"
                                          v-mask="'###############'"
                                          :rules="[genericRules.required]"
                                          class="text-fields"
                                          v-model="owner.phone">
                                <template #message="{ message }">
                                    {{ $t(message) }}
                                </template>
                            </v-text-field> -->

                            <phone-number-input v-model="owner.phone"
                                                @input="handlePhone"
                                                :default-country-code="'SA'"
                                                v-bind="$attrs"
                                                style="border: none;"
                                                :color="'#00b2a9'"
                                                :disabled="view"
                                                :minLength="8"
                                                :maxlength="11"
                                                class="code-select" />
                            <span v-if="isPhoneLength"
                                  style="color: red;font-size: 11px;">{{ $t('Please enter valid Mobile Number') }}</span>

                        </v-col>
                        <v-col cols="12"
                               lg="4"
                               md="6"
                               sm="12">
                            <p class="font-weight-semibold mb-0 text-black">
                                {{ $t('Email ID') }}
                            </p>
                            <v-text-field :placeholder="$t('Email')"
                                          variant="underlined"
                                          :rules="[genericRules.required, genericRules.email]"
                                          :disabled="view"
                                          class="text-fields"
                                          v-model="owner.email"></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row class="mt-5">
                        <v-col cols="12"
                               md="12"
                               sm="12">
                            <div class="d-flex align-center">
                                <span class="text-fields font-weight-semibold text-base text-green">
                                    {{ $t('Account Information') }}
                                </span>
                            </div>
                        </v-col>

                        <v-col cols="12"
                               lg="4"
                               md="6"
                               sm="12">
                            <p class="font-weight-semibold mb-0 text-black">
                                {{ $t('Bank Name') }}
                            </p>
                            <v-text-field :placeholder="$t('Bank Name')"
                                          variant="underlined"
                                          :rules="[genericRules.required]"
                                          :disabled="view"
                                          class="text-fields"
                                          v-model="owner.bank_name"></v-text-field>
                        </v-col>
                        <v-col cols="12"
                               lg="4"
                               md="6"
                               sm="12">
                            <p class="font-weight-semibold mb-0 text-black">
                                {{ $t('Branch') }}
                            </p>
                            <v-text-field :placeholder="$t('Branch')"
                                          variant="underlined"
                                          :rules="[genericRules.required]"
                                          :disabled="view"
                                          class="text-fields"
                                          v-model="owner.bank_branch"></v-text-field>
                        </v-col>
                        <v-col cols="12"
                               lg="4"
                               md="6"
                               sm="12">
                            <p class="font-weight-semibold mb-0 text-black">
                                {{ $t('IBAN') }}
                            </p>
                            <v-text-field :placeholder="$t('IBAN')"
                                          variant="underlined"
                                          :disabled="view"
                                          :rules="[genericRules.required]"
                                          class="text-fields"
                                          v-model="owner.bank_iban"></v-text-field>
                        </v-col>

                    </v-row>
                    <div class="mt-5"
                         v-if="!view">
                        <v-btn color="primary"
                               class="text-lower owner-info-btn border-card"
                               @click="submit">
                            {{ $t('Save') }}
                        </v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-form>
    </div>
</template>

<script>
import router from '@/router';
import darkMode from '@core/utils/modalColor';
import userServices from '@/services/user-services';
// import { useUtils as useAclUtils } from '@core/libs/acl';
// import { useUtils } from '@core/libs/i18n';
import VuePhoneNumberInput from 'vue-phone-number-input';
// import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import { useUtils as useAclUtils } from '@core/libs/acl';
import { useUtils } from '@core/libs/i18n';

import PhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

// import router from '@/router';
import sharedServices from '@/services/shared-services';

import store from '@/store';

import countries from '@/views/countries';

export default {
    components: {
        PhoneNumberInput
    },
    data: () => ({
        generalForm: null,
        fieldIsFilled: false,
        view: false,
        lengthRules: [
            v => !!v || "This field is required"
        ],
        showLengthField: true,
        genericRules: {
            required: v => !!v || 'Field is required',
            numbers: v => /^[+0-9,.]*$/.test(v) || 'Only numbers are allowed',
            characters: v => /^[a-z A-Z]*$/.test(v) || 'Only characters are allowed',
            email: v => /.+@.+/.test(v) || 'E-mail must be valid',
        },
        cities: [],
        marinas: [],
        countries: [],
        type: 'Add',
        boatOwners: [],
        boatCaptains: [],
        ownerRole: false,
        categories: [],
        yearList: [],
        boatLengthValues: [],
        latLngInput: '',

        valid: false,
        view: false,
        state: 'Add New',

        isPhoneLength: false,

        numberRule: [
            value => !!value || 'Phone Number is required.',
            value => (value && value.length >= 10) || 'Number must be 10 digits.',
        ],

        roleOptions: [
            // { id: 1, role_name: 'admin' },
            { id: 2, role_name: 'Approver' },
            { id: 3, role_name: 'Initiator' }
        ],
        ownerTypes: ['Individual', 'Corporate'],

        owner: {
            owner_name: '',
            name_ar: '',
            owner_type: '',
            information_id: '',
            certificate: '',
            country_id: '',
            unit: '',
            address: '',
            district: '',
            structure_number: '',
            city: '',
            phone: '',
            phone: '',
            email: '',
            bank_name: '',
            bank_branch: '',
            bank_iban: '',
            // first_name: '',
            // last_name: '',
            // unit: '',
            // type: '',
            // street: '',
            // id_no: '',
            // district: '',
            // certification: '',
            // owner_city: '',
            // country_id: '',
            // bank_name: '',
            // branch: '',
            // phone: null,
            // email: '',
            // iban: '',
            // owner_id: null,
            // bank_iban: null,
            role: 'boat_owner',
        },
        allCities: [],
        allCountries: countries.getCountry(),
        route: router.currentRoute.params.id

    }),
    mounted()
    {
        if (this.route)
        {
            this.getUser();
        }

        // this.getCities();
        // this.getCountries();
        let country_id = this.allCountries[0];
        this.allCities = countries.getCities(country_id);

        if (router.currentRoute.params.type == 'view')
        {
            this.view = true;
        }

        let userData = store.get('accounts/userData');
        if (userData.role == 'boat_owner')
        {
            this.ownerRole = true;
            this.myboat.owner_id = userData.id;
            store.set('boatRegistrations/owner_id', this.myboat.owner_id);
        }

    },
    methods: {
        // selectCountry()
        // {
        //     this.allCities = countries.getCities(this.myboat.country_id);
        // },
        validateInput(event)
        {
            // Get the key code of the pressed key
            const keyCode = event.keyCode || event.which;

            // Allow alphabets (a-z, A-Z), spaces (32), and backspace (8)
            const validKeyCodes = [32, 8];
            for (let i = 65; i <= 90; i++)
            {
                validKeyCodes.push(i);
            }
            for (let i = 97; i <= 122; i++)
            {
                validKeyCodes.push(i);
            }

            // If the pressed key is not in the valid key codes, prevent it
            if (!validKeyCodes.includes(keyCode))
            {
                // event.preventDefault();
            }
        },

        handlePhone()
        {
            if (this.owner.phone.length < 8)
            {
                this.isPhoneLength = true;
                return;
            }
            else
            {
                this.isPhoneLength = false;
            }
        },
        getCities()
        {
            sharedServices.getCities().then(resp =>
            {
                // console.log(resp)
                this.cities = resp.data.cities;
            })
        },
        getCountries()
        {
            sharedServices.getCountries().then(resp =>
            {
                // console.log(resp)
                this.countries = resp.data.countries;
            })
        },
        backToList()
        {
            router.push({ name: 'owner-list' })

        },
        getUser()
        {

            userServices.getUser(this.route).then(resp =>
            {
                // console.log();
                if (resp.statusCode == 200)
                {
                    this.owner = resp.data.users[0].boats_owner;
                    this.owner.name_ar = resp.data.users[0].name_ar;
                }
            })
        },
        submit()
        {
            this.owner['first_name'] = this.owner.owner_name;
            this.owner['name_ar'] = this.owner.name_ar;
            
            this.$refs.form.validate();
            if (this.$refs.form.validate())
            {
                if (!this.route)
                {
                    userServices.createUser(this.owner).then(res =>
                    {
                        if (res?.error?.response?.data?.message)
                        {
                            this.$swal({
                                icon: 'error',
                                title: 'Error',
                                confirmButtonColor: darkMode.getTextColor(),
                                customClass: darkMode.getBackgroundColor(),
                                text: res.error.response.data.message,
                            })
                        }
                        else if (res?.data?.statusCode == 200)
                        {
                            this.$swal({
                                text: `Boat owner has been added successfully`,
                                confirmButtonColor: darkMode.getTextColor(),
                                customClass: darkMode.getBackgroundColor(),
                                icon: 'success',
                            }).then((result) =>
                            {
                                if (result.isConfirmed)
                                {
                                    router.push('/owner/list')

                                }

                            });
                        }
                        else
                        {
                            let msg = res?.message;
                            this.$swal({
                                icon: 'error',
                                title: 'Error',
                                confirmButtonColor: darkMode.getTextColor(),
                                customClass: darkMode.getBackgroundColor(),
                                text: 'Something went wrong!',
                            })
                        }
                    });
                }
                else
                {
                    this.owner['id'] = router.currentRoute.params.id;
                    userServices.updateUser(this.owner).then(res =>
                    {
                        if (res?.error?.response?.data?.message)
                        {
                            this.$swal({
                                icon: 'error',
                                title: 'user',
                                confirmButtonColor: darkMode.getTextColor(),
                                customClass: darkMode.getBackgroundColor(),
                                text: res.error.response.data.message,
                            })
                        }
                        else if (res?.data?.statusCode == 200)
                        {
                            this.$swal({
                                text: 'Boat owner has been updated successfully',
                                confirmButtonColor: darkMode.getTextColor(),
                                customClass: darkMode.getBackgroundColor(),
                                icon: 'success',
                            }).then((result) =>
                            {
                                if (result.isConfirmed)
                                {
                                    router.push('/owner/list')

                                }

                            });
                        }
                        else
                        {
                            this.$swal({
                                icon: 'error',
                                title: 'User',
                                confirmButtonColor: darkMode.getTextColor(),
                                customClass: darkMode.getBackgroundColor(),
                                text: 'Something went wrong!',
                            })
                        }
                    });;
                    // console.log(resp);
                }

            }
        },
    },
    setup()
    {
        const { t } = useUtils()
        const { canViewVerticalNavMenuLink } = useAclUtils()

        return {
            // i18n
            t,

            // ACL
            canViewVerticalNavMenuLink,
        }
    },
}
</script>

<style lang="scss" scoped>
.v-input--selection-controls {
    margin-top: 0 !important;
    padding-top: 4px;
}

.only-divider .v-divider {
    border-width: 1px !important;
}

.text-blue {
    color: #006fcf;
}

.text-black {
    color: #000;
}

.bg-white {
    background: white;
}

.v-expansion-panel {
    box-shadow: 0px 0px 28px 2px #0000001A !important;
}

.v-application.theme--light .v-expansion-panel.v-expansion-panel--active {
    box-shadow: 0px 0px 28px 2px #0000001A !important;
}

.v-divider {
    border-color: #FFFFFF !important;
}

.text-fields {
    margin: -4px 0px 0px;
}

::v-deep .code-select {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    caret-color: none !important;
}

::v-deep .country-selector__label[data-v-46e105de] {
    position: absolute;
    top: 3px;
    cursor: pointer;
    display: none !important;
    left: 11px;
    -webkit-transform: translateY(25%);
    transform: translateY(25%);
    opacity: 0;
    -webkit-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    transition: all .25s cubic-bezier(.645, .045, .355, 1);
    font-size: 11px;
    color: #747474;
}

::v-deep .input-tel.is-focused {
    border-color: none !important;
    caret-color: none !important;
}



::v-deep .input-tel__input[data-v-e59be3b4] {
    cursor: pointer;
    background-color: #fff;
    -webkit-transition-duration: .3s;
    transition-duration: .3s;
    position: relative;
    width: 100%;
    padding: 0 12px;
    font-weight: 400;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    border: 1px solid #ccc;
    font-size: 14px;
    border-top: none !important;
    border-right: none !important;
    margin-left: -1px;
    height: 40px;
    min-height: 40px;
    border-left: none !important;
    border-color: none !important;
}



::v-deep .input-tel.has-hint .input-tel__label[data-v-e59be3b4],
.input-tel.has-value .input-tel__label[data-v-e59be3b4] {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    font-size: 11px;
    display: none !important;
}


::v-deep .input-tel.has-value .input-tel__label[data-v-e59be3b4] {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    font-size: 11px;
    display: none !important;
}

::v-deep .country-selector__input[data-v-46e105de] {
    cursor: pointer;
    background-color: #fff;
    position: relative;
    width: 100%;
    height: 40px;
    min-height: 40px;
    padding-right: 22px;
    font-weight: 400;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    border: 1px solid #ccc;
    font-size: 13px;
    border-top: none !important;
    z-index: 0;
    padding-left: 8px;
    color: #000;
    border-left: none !important;
    border-color: none !important;
}
</style>
